.loader-box {
  margin: 50px;
  width: 100px;
  height: 100px;
}

@keyframes spin {
  0% {
    stroke-dashoffset: 15;
    transform: rotate(0deg)
  }

  50% {
    stroke-dashoffset: 40;
  }

  100% {
    stroke-dashoffset: 15;
    transform: rotate(360deg);
  }
}

.spinner {
  height: 100%;
  width: 100%;
  transform-origin: center center;
  transform: rotate(0deg);

  fill: none;
  stroke: lightskyblue;
  stroke-linecap: round;
  stroke-dasharray: 100;

  animation: spin 2s infinite;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.full-screen {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--bg-color) ;
}

.full-screen .loader-box {
  height: min(40vw, 40vh);
  width: min(40vw, 40vh);
}
