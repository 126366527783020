@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans+JP:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mukta:700');

:root {
  --end-bg-color: #d4edf7ff;
  --accent-dark: #004e93ff;
  --accent-sat: #0985f1ff;

  --ty-fn-size: 60px;
  --msg-fn-size: 25px;
  --logo-size: 180px;
}

@media only screen and (max-width: 590px) {
  :root {
    --ty-fn-size: 40px;
    --logo-size: 110px;
  }
}

@media only screen and (max-width: 420px) {
  :root {
    --logo-size: 80px;
    --ty-fn-size: 35px;
    --msg-fn-size: 20px;
  }
}

@media only screen and (max-height: 360px) {
  :root {
    --logo-size: 80px;
  }
}
.expander {
  align-items: stretch;
  flex-basis: 100%;
}

.invisible {
  visibility: hidden;
}

.end-result {
  height: 100%;
  width: 100%;
}

.end-result > * {
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  background-color: var(--end-bg-color);
}

.main-box {
  display: flex;
  flex-direction: column;
  /* height: fit-content; */
  height: 100%;
  width: 100%;
}

.thank-you, .error-alert {
  margin: 0 40px;
  max-width: calc(100vw - 80px);
}

.thank-you h1, .error-alert h1 {
  color: var(--accent-sat);
  font-size: var(--ty-fn-size);
  text-align: center;
  line-height: 1.2em;
}

.message {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.message * {
  font-size: var(--msg-fn-size);
  font-weight: 500;
  text-align: center;
  color: var(--accent-dark);
}

.message .boat {
  /* color: var(--accent-sat); */
  font-weight: 800;
}

.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
}

.logo-container svg {
  height: var(--logo-size);
}

.message a {
  color: var(--accent-sat);
}

.loader-container {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.end-page-loader {
  height: 80px;
  width: 80px;
}
