@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans+JP:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mukta:700');

:root {
    --section-bg-color: rgb(243, 243, 245);
    --bg-color: #d4edf7ff;
    --hover-color: rgb(228, 235, 238);
    --active-color: rgb(205, 213, 216);
    --accent: rgb(36, 86, 151);
    --text: rgb(28, 131, 228);
    --text-snd: rgb(46, 113, 177);
    --grow-speed: 2s;
	--grad-dark: #4f7af1;
    --grad-light: #66cee0;
    --item-offset: 0;
    --side-padding: 30px;
}

* {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 15px;
    /* color: rgb(25, 58, 102); */
    color: var(--text);

    -webkit-tap-highlight-color: transparent;
}

html {
    touch-action: manipulation;
}

.bg-container {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    padding: 0;
	background: rgb(196, 228, 238);
}

.bg {
    /* transform: translateY(-40%); */
	opacity: 0;
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    z-index: -1;
}

Form {
    display: block;
    margin: auto;
    max-width: fit-content;
    height: fit-content;
}

.section-container {
    position: relative;
    box-sizing: border-box;
    max-width: 760px;
    width: 100vw;
    margin: 18px auto;
    padding: 20px var(--side-padding);

    background-color: var(--section-bg-color);
    border-radius: 15px;

    box-shadow: 15px 0 40px 0px rgba(0,0,0,.05),
                -15px 0 40px 0px rgba(0,0,0,.05);
}

.section {
    /* -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0); */

    display: block;
    background-color: var(--section-bg-color);
    width: 100%;
    margin: auto;
}

.section > * {
    margin-bottom: .8em;
}

h1 {
    font-size: 300%;
    text-align: left;
    margin: 0;
}

h3 {
    font-size: 160%;
    margin-top: 1em;
    margin-bottom: .2em;
}

h3:first-child {
    margin-top: .2em;
}

h4 {
    color: var(--text-snd);
    font-size: 120%;
    margin-top: .5em;
}

.form-label, .text-input-form-label {
    display: block;
    color: rgb(25, 58, 102);
    font-weight: 500;
    margin-left: var(--item-offset);
    margin-right: var(--item-offset);
}

.check-label {
    margin-left: 0;
}

.text-input-form-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-input, .text-area {
    position: relative;
    z-index: 2;
    cursor: text;
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;

    border-width: 0;
    border-style: solid;
    border-radius: 4px;
    border-color: rgb(36, 86, 151);

    color: rgb(32, 71, 121);
    padding: 6px;
    background: #ffffff;

    font-size: 16px;
    font-weight: 400;

    transition: box-shadow 300ms ease-in;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,.06),
                -3px -3px 3px 0px rgba(255, 255, 255, .4);

    outline: none;
}

.text-area * {
    color: rgb(32, 71, 121);
    font-size: 16px;
    font-weight: 400;
}

.text-input-container {
    position: relative;
    display: block;
    width: 100%;
    padding: 3px;
    margin: 5px 0;
    margin-left: -3px;
}

.text-input-border {
    position: absolute;
    /* background: var(--accent); */
    display: block;
    width: 100%;
    height: 46px;
    top: 0;
    left: 0;
    z-index: 1;

    overflow: hidden;

    border-radius: 6px;

    transition: transform 200ms ease-out;
    transform: scaleX(0);
    transform-origin: center center;

}

.text-input-glow {
    position: aboslute;
    display: block;
    left: 0;
    height: 100%;
    width: 160%;

    /* background: radial-gradient(circle at 50% 50%, #80e0e8, #3d6cf7); */
    background: radial-gradient(circle at 50% 50%, var(--grad-light), var(--grad-dark));
    animation-name: bg-float;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.text-input:focus ~ .text-input-border {
    transform: scaleX(1);
    box-shadow: 3px 3px 8px 3px rgba(0,0,0,.09),
                -7px -7px 4px 0px rgba(255, 255, 255, .4);
}

.text-input {
    position: relative;
    min-height: 40px;
    margin: 0;
    padding-top: .5em;

    white-space: nowrap;
    word-wrap: none;
    overflow-x: scroll;
    scrollbar-width: none;

    z-index: 2;
}

.text-input::-webkit-scrollbar {
    display: none;
}

.text-input:focus, .text-area:focus, .text-input.error {
    box-shadow: none;
}

.text-input.error ~ .text-input-border .text-input-glow {
    background: rgb(131, 27, 27);
}
.text-input.error ~ .text-input-border {
    transform: scaleX(1);
}

.text-area-container {
    position: relative;
    margin: 5px 0;
    margin-left: -3px;
    padding: 3px;
    height: fit-content;
    width: 100%;
}

.text-area-border {
    position: absolute;
    /* background: var(--accent); */
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border-radius: 6px;

    overflow: hidden;

    transition: transform 200ms ease-out;
    transform: scaleX(0);
}
.text-area:focus + .text-area-border {
    transform: scaleX(1);
    box-shadow: 3px 3px 8px 3px rgba(0,0,0,.09),
                -7px -7px 4px 0px rgba(255, 255, 255, .4);
}

@keyframes bg-float {
    0% {
        transform: translateX(-30%)
    }
    50% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(-30%)
    }
}

.text-area {
    min-height: 50px;
    white-space: pre-wrap;
}


input[type="checkbox"], input[type="radio"] {
    display: none;
}

.check-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    margin: 5px;
	margin-left: 0;

    box-sizing: border-box;

    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    fill: white;
    border-radius: 15%;
    stroke-width: 13px;

    outline: none;
    
    filter: drop-shadow(3.5px 1px 2px rgba(0,0,0,.05))
            drop-shadow(1px 3.5px 2px rgba(0,0,0,.05));

    transition: stroke-dashoffset 280ms ease-in-out;
}

.check {
    transition: stroke-dashoffset 220ms ease-in-out;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
}

.check-entry {
    transform: translateZ(0);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    margin-left: var(--item-offset);
    outline: none;
}

.radio {
    stroke-width: 2.4px;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    transition: stroke-dashoffset 200ms ease-in-out;
}

.check-entry.checked .check-icon, .check-entry.checked .check {
    stroke-dashoffset: 200;
}

.check-entry.checked .check-icon {
    fill: white;
}

.radio-check {
    transition: transform 200ms ease-in-out;
    transform-origin: center center;
    transform: scale(0);
    stroke: none;
    /* fill: rgb(36, 86, 151); */
}

.check-entry.checked .radio {
    stroke-dashoffset: 200;
}
.check-entry.checked .radio-check {
    stroke: none;
    transform: scale(.6);
}

@media (hover: hover) {
    .check-entry:hover .check-icon, .check-entry:focus-visible .check-icon {
        fill: var(--hover-color);
    }
}

.check-entry:active .check-icon {
    fill: var(--active-color);
    filter: none;
}
input[type="checkbox"]:disabled ~ .check-entry * {
    filter: none;
    fill: rgb(199, 199, 199);
    stroke: rgb(199, 199, 199);
    cursor: not-allowed;
}

input[type="checkbox"]:disabled ~ .check-entry {
    cursor: not-allowed;
}

input[type="checkbox"]:disabled:checked ~ .check-entry .check-icon * {
    stroke: rgb(121, 121, 121);
}

.checkgroup h4, .radiogroup h4 {
    margin-bottom: .5em;
}

.radio-entry {
    cursor: pointer;
}

.radiogroup, .checkgroup {
    margin-left: 0;
}

.form-select {
    display: block;
    position: relative;
    margin-bottom: 1.2em;
}

.form-select .form-label {
    margin-bottom: .4em;
}

.select-popup, .select-open {
    width: 200px;
    margin-left: var(--item-offset);
}

.select-open {
    box-sizing: border-box;
    margin-top: 5px;

    background: none;
    border: none;
    outline: none;
    border-radius: 8px;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,.18),
                -3px -3px 5px 0px rgba(255,255,255,.95);

    font-weight: 500;
}

.select-popup {
    display: block;
    z-index: 10;
    overflow: hidden;
    position: absolute;

    margin-top: -40px;

    box-shadow: 3px 3px 3px -1px rgba(0,0,0,.07),
                -3px 0 3px -1px rgba(0,0,0,.07);
    border-radius: 8px;

    background-color: var(--section-bg-color);

    outline: none;
}

.select-popup.enter {
    max-height: 300px;
}

.select-open:active {
    box-shadow: none;
}

.select-open, .option {
    cursor: pointer;
    height: 40px;
    text-align: start;
    background: rgb(240, 245, 247);
    color: rgb(25, 58, 102);

    padding: 0 .5em;
    overflow: hidden;
}

.select-open i {
    font-size: larger;
    font-weight: 700;
}

.select-popup i {
    font-size: smaller;
}

.select-popup .ibox {
    margin-top: -.2em;
}

.select-sep {
    display: block;
    height: 1px;
    width: 90%;
    background-color: rgba(0,0,0,.1);
    margin: auto;
}

.option {
    display: block;
    width: 100%;
    border: none;
    outline: none;
}

.opt-box {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    color: rgb(25, 58, 102);
}

.choice {
    position: relative;
    font-weight: 500;
    z-index: 5;
}

.non-chosen {
    transform: translateY(-100%);
    z-index: 1;
}

.select-popup.enter .non-chosen {
    transform: translateY(0);
}

.option:hover, .option:focus-visible,
.select-open:hover, .select-open:focus-visible {
    outline: none;
    background-color: var(--hover-color);
}

.option:active, .select-open:active {
    background-color: var(--active-color);
}

.grow {
    transform: scale(0);
    overflow: hidden;
}

.header {
    margin-top: 14px;
    margin-bottom: 1em;
}

.header h1 {
    text-align: left;
}

.header h2 {
    font-style: italic;
    font-size: 26px;
    color: var(--text-snd);
    margin: 0;
}

.lang-choose {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
}
    
.lang-choose .select-open, .lang-choose .option {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
}

.lang-choose .opt-box {
    width: 5.2em;
}

.ibox {
    width: 1em;
    margin-left: auto;
}

.lang-choose button, .lang-choose .select-popup {
    width: max-content;
}

.flag-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 1.5em;
    background: rgb(174, 186, 204);
    height: 100%;
    margin-right: .4em;
    padding: 0 .4em;
}

.flag {
    height: 60%;
}

.fire-ext-summary {
    display: block;
    margin-top: 0;
}

.fire-ext-summary .text-input-container {
    display: inline-block;
    width: 40px;
    margin-left: 5px;
}

.fire-ext-summary .text-input-form-label {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}

.error-msg {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(255, 226, 226);
    border: rgb(131, 27, 27) solid 2px;
    border-radius: 5px;
}

.error-msg * {
    color: rgb(109, 22, 22);
}

#num-fire-exts {
    margin-bottom: 0;
    margin-left: 0;
}
.submit {
    cursor: pointer;
    display: block;
    width: 180px;
    height: 40px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;

	font-size: 19px;
	font-weight: 700;
	font-family: 'Mukta';

    box-shadow: 2.5px 2.5px 8px 0px rgba(0,0,0,.2);

	background: linear-gradient(140deg, var(--grad-light), var(--grad-dark));
	border: none;
	border-radius: 20px;
	color: white;
}

.submit:hover {
	filter: brightness(.9);
}

.submit:focus {
    outline: none;
}

.submit:active {
    box-shadow: inset 1px 1px 2px 0 rgba(0,0,0,.05),
                inset -1px -1px 2px 0 rgba(255,255,255,.4);
    background-color: rgb(230, 237, 240);
    border: none;
}

.wax-jobs h4 {
    margin-right: .5em;
}

.info {
    position: relative;
    display: flex;
    align-items: baseline;
    width: max-content;
    padding-right: .8em;
}

.info-text {
    opacity: 0;
    position: absolute;
    left: 100%;
    top: 1em;
    width: 10em;

    color: var(--accent);
    background: white;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,.1);
    border-radius: 5px;
    border: 2px solid var(--accent);
    padding: .5em;

    transition: opacity 100ms ease-out;
}

.info i:hover ~ .info-text, .info i:focus ~ .info-text {
    opacity: 1;
}

.info i {
    height: 100%;
    font-size: 1.4em;
    color: rgb(84, 161, 233);
    outline: none;
}

.info i:hover {
    color: rgb(67, 143, 214);
    cursor: pointer;
}

#boat-submit i {
    width: fit-content;
    margin-left: .5em;
    font-size: 90%;
	color: white;
}

#dmarin-top-logo {
    height: 40px;
    width: fit-content;
    position: relative;
    margin: 0;
}

@media only screen 
and (min-width: 390px) {
    #dmarin-top-logo {
        height: 50px;
    }
}

@media only screen
and (min-width: 460px) {
    .section {
        width: 500px;
        max-width: 100%;
    }

    .section-container {
        padding: 20px 45px;
    }
}

@media only screen
and (min-width: 630px) {
    .select-open, .option {
        height: 35px;
    }

    .select-popup {
        margin-top: -35px;
    }

	.header {
		display: block;
		align-items: none;
	}

    .lang-choose .opt-box {
        width: 8em;
    }
    
    #dmarin-top-logo {
        height: 60px;
    }
}

@media only screen
and (max-width: 485px) {
    .header {
        max-width: 200px;
        font-size: 12px;
    }
}

@media only screen
and (max-width: 405px) {
    .info-text {
        width: 7em;
    }
}
